import React from 'react';
import LayoutTextPage from '../layout/layoutTextPage';
import { mq, theme } from '../theme';
import { Link } from 'gatsby';

class FeedbackPage extends React.PureComponent<{}, {}> { 
    public render() {
        return (
            <LayoutTextPage title='Klachten/ Feedback'>
                <div
                    css={mq({
                    maxWidth:'90vw',
                    margin:'auto',
                })}
                >
                    <div>Indien u klachten of feedback heeft dan vernemen wij dit graag.<br/>
                    U kunt ons per email bereiken op <a href="mailto:backoffice@smartsalescompany.nl">backoffice@smartsalescompany.nl</a> of op telefoonummer 030 249 81 81</div>
                </div>   
                <div
                    css={mq({
                        maxWidth: '90vw',
                        margin: 'auto',
                        marginTop: '20px'
                    })}
                >
                    <div>Afmelden van onze contactlijst kan via <Link to={'/afmelden'}>onze afmeldpagina</Link></div>
                </div>   
        </LayoutTextPage>
        )}
}

export default FeedbackPage;
