import React from 'react';
import Layout from '.';
import { mq } from '../theme';

type TLayoutTextPageProps = {
  title: string;
  noTitle: boolean;
  children: React.ReactNode | React.ReactNode[];
}

const LayoutTextPage = ({ title, noTitle= false, children }: TLayoutTextPageProps) => {
  return (
    <Layout>
      {!noTitle && (
      <div
        css={(theme: any) => mq({
          width: '100vw',
          backgroundColor: theme.colors.primary_accent,
          height: '25vh',
          minHeight: 150,
          maxHeight: [200, null, 300],
          display: 'table',
          marginBottom: '5vw',
        })}
      >
          <h1
            css={(theme: any) => mq({
              textAlign: 'center',
              fontSize: [
                theme.fontSize.headerSmall,
                theme.fontSize.headerMedium,
                theme.fontSize.headerRegular,
              ],
              fontWeight: 600,
              verticalAlign: 'middle',
              display: 'table-cell',
              color: theme.colors.white,
            })}
          >
            {title}
          </h1>
        
      </div>
      )}
      {children}
    </Layout>
  );
}

export default LayoutTextPage;
