import React from 'react';
import LayoutTextPage from '../layout/layoutTextPage';
import { mq, theme } from '../theme';

class PrivacyPage extends React.PureComponent<{}, {}> {
    public render() {
        return (
            <LayoutTextPage title='Privacy Voorwaarden'>
                <div
                    css={mq({
                        maxWidth: ['100%', '900px'],
                        margin: 'auto',
                        padding: '20px',
                        fontFamily: 'system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif',
                        lineHeight: '1.6',
                        color: '#333',
                    })}
                >
                    <div css={{
                        backgroundColor: '#f9f9f9',
                        padding: '20px',
                        marginBottom: '20px',
                        borderRadius: '4px',
                        boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                    }}>
                        <div css={{
                            '& p': {
                                marginBottom: '15px',
                            },
                            '& ul': {
                                backgroundColor: 'white',
                                borderRadius: '4px',
                                padding: '15px 30px',
                                marginBottom: '15px',
                                boxShadow: '0 1px 3px rgba(0,0,0,0.1)',
                            },
                            '& li': {
                                marginBottom: '10px',
                                paddingLeft: '10px',
                                position: 'relative',
                                '&:before': {
                                    content: '"•"',
                                    color: '#007bff',
                                    position: 'absolute',
                                    left: '-15px',
                                    fontWeight: 'bold',
                                },
                            },
                            '& h2': {
                                color: '#007bff',
                                borderBottom: '1px solid #e0e0e0',
                                paddingBottom: '10px',
                                marginTop: '30px',
                                marginBottom: '15px',
                            },
                        }}>
                            <h1 css={{
                                color: '#007bff',
                                borderBottom: '2px solid #007bff',
                                paddingBottom: '10px',
                                marginBottom: '20px',
                            }}>Privacyverklaring</h1>

                            <p>Via onze website worden privacygevoelige gegevens, oftewel persoonsgegevens, verwerkt. Smart Sales Company b.v. acht een zorgvuldige omgang met persoonsgegevens van groot belang. Persoonlijke gegevens worden door ons dan ook zorgvuldig verwerkt en beveiligd.</p>

                            <p>Bij deze verwerking houden wij ons strikt aan de eisen die de privacywetgeving stelt, waaronder de Algemene Verordening Gegevensbescherming (AVG) en de Telecommunicatiewet. Dit betekent onder andere dat:</p>

                            <ul>
                                <li>Wij duidelijk vermelden met welke doeleinden wij persoonsgegevens verwerken. Dat doen wij via deze privacyverklaring;</li>
                                <li>Wij onze verzameling van persoonsgegevens beperken tot alleen de persoonsgegevens die nodig zijn voor legitieme doeleinden;</li>
                                <li>Wij u eerst vragen om uitdrukkelijke toestemming om uw persoonsgegevens te verwerken in gevallen waarin uw toestemming is vereist, bijvoorbeeld voor direct marketing of nieuwsbriefinschrijvingen;</li>
                                <li>Wij passende beveiligingsmaatregelen nemen om uw persoonsgegevens te beschermen en dat ook eisen van partijen die in onze opdracht persoonsgegevens verwerken;</li>
                                <li>Wij uw recht respecteren om uw persoonsgegevens op uw aanvraag ter inzage te bieden, te corrigeren, te verwijderen of om de verwerking ervan te beperken.</li>
                            </ul>

                            <p>Smart Sales Company b.v. is verantwoordelijk voor de gegevensverwerking. In deze privacyverklaring leggen wij uit welke persoonsgegevens wij verzamelen en gebruiken en met welk doel. Wij adviseren u deze verklaring zorgvuldig door te nemen.</p>

                            <p css={{ fontStyle: 'italic', color: '#666' }}>Deze privacyverklaring is voor het laatst gewijzigd op 09-11-2024.</p>

                            <h2>Gebruik van persoonsgegevens</h2>
                            <p>Bij het gebruiken van onze website verkrijgen wij bepaalde gegevens van u. Dat kunnen persoonsgegevens zijn. Wij bewaren en gebruiken uitsluitend de persoonsgegevens die rechtstreeks door u worden opgegeven, in het kader van de door u gevraagde dienst, of waarvan bij opgave duidelijk is dat ze aan ons worden verstrekt om te verwerken.</p>

                            <p>Wij gebruiken de volgende gegevens voor de in deze privacyverklaring genoemde doelen:</p>
                            <ul>
                                <li>Voornaam</li>
                                <li>Achternaam</li>
                                <li>Postcode</li>
                                <li>Huisnummer/toevoeging</li>
                                <li>Bedrijfsnaam</li>
                                <li>Telefoonnummer</li>
                                <li>Factuuradres</li>
                                <li>E-mailadres</li>
                                <li>Betalingsgegevens</li>
                                <li>Geslacht</li>
                                <li>Huidige provider</li>
                                <li>IP-adres</li>
                            </ul>

                            <h2>Met welk doel en op basis van welke grondslag wij persoonsgegevens verwerken</h2>
                            <p>Smart Sales Company B.V. verwerkt uw persoonsgegevens voor de volgende doelen:</p>
                            <ul>
                                <li>U te kunnen bellen of e-mailen indien dit nodig is om onze dienstverlening uit te kunnen voeren</li>
                                <li>U te informeren over wijzigingen van onze diensten en producten</li>
                                <li>Om goederen en diensten bij u af te leveren</li>
                                <li>Om u op de hoogte te stellen van nieuwe producten en diensten via marketingcommunicatie, indien u hiervoor toestemming heeft gegeven</li>
                                <li>Om de inhoud van onze diensten en advertenties af te stemmen op uw voorkeuren, gebaseerd op uw surfgedrag, als u hiermee heeft ingestemd</li>
                            </ul>

                            <h2>Afhandeling van bestellingen</h2>
                            <p>Wanneer u bij ons een bestelling plaatst, maken wij voor de afhandeling daarvan gebruik van uw persoonsgegevens. Indien dat nodig is voor een goede afhandeling, kunnen wij uw persoonsgegevens ook aan derden verstrekken. Meer daarover leest u verderop in deze privacyverklaring.</p>

                            <h2>Reclame</h2>
                            <p>Wij kunnen u, naast de informatie op onze website, ook op de hoogte brengen van onze nieuwe producten en diensten:</p>
                            <ul>
                                <li>Per e-mail</li>
                                <li>Via sociale media</li>
                                <li>Telefonisch</li>
                            </ul>
                            Indien u geen reclame- of marketingcommunicatie meer wenst te ontvangen, kunt u zich eenvoudig afmelden via de daarvoor aangegeven link in de communicatie of door contact met ons op te nemen.

                            <h2>Contactformulier en nieuwsbrief</h2>
                            <p>Wij bieden via onze website de mogelijkheid om vragen te stellen middels een contactformulier, waarbij u gevraagd wordt diverse gegevens in te vullen om uw vraag te behandelen. U kiest zelf welke gegevens u daarbij verstrekt. De gegevens die u ons toestuurt, worden bewaard zolang als naar de aard van het formulier of de inhoud van uw e-mail nodig is voor de volledige beantwoording en afhandeling daarvan.</p>

                            <p>Indien u ons via het contactformulier verzoekt om contact met u opnemen voor de aankoop van een product of dienst, nemen wij doorgaans telefonisch contact met u op.</p>

                            <p>Wij bieden een nieuwsbrief waarmee wij geïnteresseerden willen informeren over onze producten en/of diensten. Indien u zich inschrijft voor de nieuwsbrief, ontvangt u deze enkel als u daarvoor expliciet toestemming heeft gegeven. Iedere nieuwsbrief bevat een link waarmee u zich kunt afmelden. Uw e-mailadres wordt automatisch toegevoegd aan de lijst van abonnees en wordt enkel bewaard zolang u zich niet afmeldt voor de nieuwsbrief.</p>

                            <h2>Publicatie</h2>
                            <p>Wij publiceren uw klantgegevens niet zonder uw toestemming.</p>

                            <h2>Advertenties</h2>
                            <p>Onze website kan advertenties vertonen. Indien deze advertenties zijn gepersonaliseerd op basis van uw persoonsgegevens, doen wij dit alleen als u hiervoor toestemming heeft gegeven.</p>

                            <h2>Delen van persoonsgegevens met derden</h2>
                            <p>Smart Sales Company b.v. deelt uw persoonsgegevens met verschillende derden als dit noodzakelijk is voor het uitvoeren van de overeenkomst en om te voldoen aan een eventuele wettelijke verplichting. Met bedrijven die uw gegevens verwerken in onze opdracht, sluiten wij een verwerkersovereenkomst om te zorgen voor eenzelfde niveau van beveiliging en vertrouwelijkheid van uw gegevens. Wij verkopen uw persoonsgegevens nooit aan derden.</p>

                            <h2>Cookies</h2>
                            <p>Op onze website maken wij gebruik van cookies. Ook derden die door ons zijn ingeschakeld, plaatsen cookies.</p>
                            <p>Wanneer u onze website voor het eerst bezoekt, krijgt u een mededeling te zien waarin wij uitleggen waarom wij cookies gebruiken. Uw verdere gebruik van onze website wordt beschouwd als toestemming voor het plaatsen van cookies, tenzij u uw instellingen voor cookies aanpast.</p>
                            <p>U kunt cookies uitschakelen via uw browserinstellingen. Houd er rekening mee dat onze website dan mogelijk niet optimaal werkt.</p>
                            <p>Met derden die cookies plaatsen, hebben wij afspraken gemaakt over het gebruik van de cookies en applicaties. Toch hebben wij geen volledige controle op wat de aanbieders van deze applicaties zelf met de cookies doen wanneer zij deze uitlezen. Voor meer informatie over deze applicaties en hoe zij met cookies omgaan, verwijzen wij u naar de privacyverklaring(en) van deze derden (let op: deze kunnen regelmatig wijzigen).</p>

                            <h2>Google Analytics</h2>
                            <p>Wij gebruiken Google Analytics om het gebruik van onze website te analyseren. We hebben een verwerkersovereenkomst met Google gesloten en hebben de instellingen geconfigureerd zodat de gegevens geanonimiseerd worden verwerkt.</p>

                            <h2>Beveiliging</h2>
                            <p>Wij treffen beveiligingsmaatregelen om misbruik van en ongeautoriseerde toegang tot persoonsgegevens te voorkomen, waaronder:</p>
                            <ul>
                                <li>Toegang tot persoonsgegevens wordt afgeschermd met een gebruikersnaam, wachtwoord en 2FA.</li>
                                <li>Wij maken gebruik van beveiligde verbindingen (SSL) voor de overdracht van gegevens.</li>
                            </ul>

                            <h2>Bewaartermijnen</h2>
                            <p>De hierboven beschreven persoonsgegevens worden maximaal de periode die strikt noodzakelijk is voor het doel van de verwerking bewaard, tenzij een langere bewaartermijn vereist is op grond van wettelijke verplichtingen. Bijvoorbeeld, gegevens voor belastingdoeleinden worden minimaal zeven jaar bewaard.</p>
                            <p>Opt-in gegevens worden niet langer dan twee jaar bewaard, tenzij u expliciet opnieuw toestemming geeft voor verdere verwerking. Na afloop van de bewaartermijn worden alle gegevens, inclusief opt-in gegevens, volledig gewist.</p>
                            <p>Daarnaast bewaren wij verkeersgegevens (zoals het tijdstip en de duur van communicatie, en de betrokken telefoonnummers) maximaal zes maanden, zoals vereist door de Telecommunicatiewet.</p>

                            <h2>Inzicht in Opt-in Gegevens</h2>
                            <p>U heeft te allen tijde het recht om inzicht te krijgen in de opt-in gegevens die wij van u hebben geregistreerd. Om dit te doen, kunt u een verzoek indienen door een e-mail te sturen naar verzoeken@quicknetsolutions.nl of via onze "afmelden van contactlijst" pagina. Vermeld in uw e-mail duidelijk uw volledige naam en eventuele aanvullende informatie die ons kan helpen uw gegevens te lokaliseren.</p>
                            <p>Na ontvangst van uw verzoek zullen wij binnen vier weken de gevraagde informatie verstrekken. Mocht er aanvullende verificatie nodig zijn om uw identiteit vast te stellen, dan zullen wij u hierover tijdig informeren. Uw gegevens worden met de grootste zorg en in overeenstemming met de geldende privacywetgeving behandeld.</p>

                            <h2>Websites van derden</h2>
                            <p>Deze privacyverklaring is niet van toepassing op websites van derden die door middel van links met onze webwinkel zijn verbonden. Wij kunnen niet garanderen dat deze derden op een betrouwbare of veilige manier met uw persoonsgegevens omgaan. Wij raden u aan de privacyverklaring van deze websites te lezen alvorens van deze websites gebruik te maken.</p>

                            <h2>Wijzigingen in deze privacyverklaring</h2>
                            <p>Wij behouden ons het recht voor om wijzigingen aan te brengen in deze privacyverklaring. Wij zullen u informeren over belangrijke wijzigingen. Wij adviseren u daarom om deze verklaring regelmatig te raadplegen.</p>

                            <h2>Inzage en wijzigen van uw gegevens</h2>
                            <p>Voor vragen over ons privacybeleid of verzoeken tot inzage, wijziging, verwijdering of beperking van uw persoonsgegevens kunt u contact met ons opnemen.</p>
                            <p>Om misbruik te voorkomen, kunnen wij u vragen om uzelf adequaat te identificeren. Voor inzage in persoonsgegevens gekoppeld aan een cookie, dient u een kopie van het betreffende cookie mee te sturen, te vinden in de instellingen van uw browser.</p>

                            <h2>Autoriteit Persoonsgegevens</h2>
                            <p>Als u klachten heeft over de verwerking van uw persoonsgegevens, helpen wij u graag verder. U heeft ook het recht om een klacht in te dienen bij de Autoriteit Persoonsgegevens.</p>

                        </div>
                    </div>
                </div>
            </LayoutTextPage>
        )
    }
}

export default PrivacyPage;