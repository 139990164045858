import styled from "@emotion/styled";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import { mq, theme } from "../../theme";
import { Text } from "../Text/Text";

const FooterText = styled(Text)((props: any ) => mq({
  color: props.theme.colors.footer_text,
  fontWeight: 500,
  marginTop: ['0.50em', null, '0.75em'],
  marginBottom: ['0.50em', null, '0.75em'],
}))

const FooterLink = styled(Link)((props: any) => ({
  ':hover > *': {
    textDecoration: 'underline'
  }
}))

const Footer = () => {
  return (
    <footer css={{ 
      backgroundColor:theme.colors.footer,
    }}>
      <div css={mq({
        display:'grid', 
        gridTemplateColumns: ['1fr', '1fr 1fr', '1fr 1fr 1fr'],
        gap: 20,
        paddingLeft: 20,
        paddingRight: 20,
        margin: '0 auto',
        width:'100%',
        maxWidth: [null, '70%', 860],
        paddingTop: [60, 60, 100],
        paddingBottom: [0, 0, 100],
      })}>
        <div
          css={mq({
            display: ['flex', 'initial'],
            flexDirection: ['column', 'initial'],
            alignItems: ['center', 'initial'],
          })}
        >
          <Text 
            onDark
            uppercase
            emphasize
            block
          >
            SmartSalesCompany
          </Text>
          <FooterLink
            to="/terms"
          >
            <FooterText 
              onDark
              block
            >
              Algemene voorwaarden
            </FooterText>
          </FooterLink>
          {/* <FooterLink
            to="/privacy"
          >
            <FooterText 
              onDark
              block
            >
              Privacybeleid
            </FooterText>
          </FooterLink>
          <FooterLink
            to="/cookie"
          >
            <FooterText 
              onDark
              block
            >
              Cookiebeleid
            </FooterText>
          </FooterLink> */}
          <FooterLink
            to="/afmelden"
          >
            <FooterText 
              onDark
              block
            >
              Afmelden
            </FooterText>
          </FooterLink>
          <FooterLink
            to="/contact"
          >
            <FooterText 
              onDark
              block
            >
              Contact
            </FooterText>
          </FooterLink>
          <FooterLink
            to="/privacy"
          >
            <FooterText 
              onDark
              block
            >
              Privacy policy
            </FooterText>
          </FooterLink>
          <FooterLink
            to="/faq/veelgesteldevragen/"
          >
            <FooterText 
              onDark
              block
            >
              Veel gestelde vragen
            </FooterText>
          </FooterLink>
        </div>
        <div
          css={mq({
            display: ['flex', 'initial'],
            flexDirection: ['column', 'initial'],
            alignItems: ['center', 'initial'],
            marginTop: [50, 'initial'],
          })}
        >
          <Text 
            onDark
            block
            uppercase
            emphasize
          >
            Klantenservice
          </Text>
          <FooterLink
            href="mailto:backoffice@smartsalescompany.nl"
          >
            <FooterText 
              onDark
              block
            >
              backoffice@smartsalescompany.nl
            </FooterText>
          </FooterLink>
          <FooterLink
            href="tel:0302498181"
          >
            <FooterText 
              onDark
              block
            >
              030 249 81 81
            </FooterText>
          </FooterLink>
        </div>
        <div
          css={mq({
            display: ['flex', null, 'initial'],
            gridColumn: ['span 1', 'span 2', 'initial'],
            flexDirection: ['column', null, 'initial'],
            justifyContent: ['center', null, 'initial'],
            alignItems: ['center', null, 'initial'],
            marginTop: [50, null, 'initial'],
            marginBottom: [50, null, 'initial'],
          })}
        >     
          <Text 
            onDark
            block
            uppercase
            emphasize
          >
            Gecertificeerde ziggo partner
          </Text>
          <StaticImage
            css={{
              maxWidth: 230
            }}
            src="../../images/fotos/vodafone-ziggo-color.png"
            alt="Vodafone en ziggo logo"
            placeholder="blurred"
            layout="constrained"
            width={230}
            height={57}
          />
          <FooterText
            onDark
            block
          >
            Onderdeel van Smart Sales Company
          </FooterText>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
