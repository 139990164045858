// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("./../../../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-afmelden-tsx": () => import("./../../../src/pages/afmelden.tsx" /* webpackChunkName: "component---src-pages-afmelden-tsx" */),
  "component---src-pages-bedrijfsgegevens-tsx": () => import("./../../../src/pages/bedrijfsgegevens.tsx" /* webpackChunkName: "component---src-pages-bedrijfsgegevens-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-cookie-tsx": () => import("./../../../src/pages/cookie.tsx" /* webpackChunkName: "component---src-pages-cookie-tsx" */),
  "component---src-pages-faq-[slug]-tsx": () => import("./../../../src/pages/faq/[slug].tsx" /* webpackChunkName: "component---src-pages-faq-[slug]-tsx" */),
  "component---src-pages-faq-veelgesteldevragen-tsx": () => import("./../../../src/pages/faq/veelgesteldevragen.tsx" /* webpackChunkName: "component---src-pages-faq-veelgesteldevragen-tsx" */),
  "component---src-pages-feedback-tsx": () => import("./../../../src/pages/feedback.tsx" /* webpackChunkName: "component---src-pages-feedback-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-offerte-aangevraagd-tsx": () => import("./../../../src/pages/offerte-aangevraagd.tsx" /* webpackChunkName: "component---src-pages-offerte-aangevraagd-tsx" */),
  "component---src-pages-pakketten-tsx": () => import("./../../../src/pages/pakketten.tsx" /* webpackChunkName: "component---src-pages-pakketten-tsx" */),
  "component---src-pages-privacy-tsx": () => import("./../../../src/pages/privacy.tsx" /* webpackChunkName: "component---src-pages-privacy-tsx" */),
  "component---src-pages-startnu-tsx": () => import("./../../../src/pages/startnu.tsx" /* webpackChunkName: "component---src-pages-startnu-tsx" */),
  "component---src-pages-terms-tsx": () => import("./../../../src/pages/terms.tsx" /* webpackChunkName: "component---src-pages-terms-tsx" */)
}

